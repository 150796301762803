@font-face {
  font-family: "Exbit-regular";
  src: url("../fonts/ReadexPro-Medium.eot");
  src: url("../fonts/ReadexPro-Medium.eot") format("embedded-opentype"), url("../fonts/ReadexPro-Medium.woff") format("woff"), url("../fonts/ReadexPro-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Exbit-bold";
  src: url("../fonts/conthrax-sb.eot");
  src: url("../fonts/conthrax-sb.eot") format("embedded-opentype"), url("../fonts/conthrax-sb.woff") format("woff"), url("../fonts/conthrax-sb.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  font-family: "Exbit-regular";
  font-size: 16px;
  font-weight: 100;
  line-height: 1.3;
  color: #ffffff;
  background-color: #000000;
  text-align: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.h1,
h2,
h3 {
  font-family: "Exbit-bold";
}

body .title-fnt {
  font-family: "Exbit-bold";
  line-height: 1.1;
}

.banner-sec::before {
  content: "";
  position: fixed;
  top: -20px;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/spark.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -3;
  opacity: 0.7;
}

.box {
  display: flex;
  flex-direction: column;
}
.box.swapChange {
  flex-direction: column-reverse;
}

.container {
  max-width: 1350px;
  width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(35, 18, 95, 0.5764705882);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(91, 142, 252, 0.4039215686);
}

.bnrbtn {
  background: linear-gradient(89.96deg, hsla(0, 0%, 100%, 0.05) 0.03%, hsla(0, 0%, 100%, 0.008) 49.67%, hsla(0, 0%, 100%, 0.05) 99.96%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: 5px;
  border-radius: 10px;
  display: inline-flex;
  border: 1px solid hsla(0, 0%, 100%, 0.164);
}

.os_container {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.os_row {
  display: flex;
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

header .os_col {
  flex: 1;
  justify-content: space-between;
}
header .titles {
  font-size: 30px;
  text-align: center;
}
header .wallet {
  text-align: right;
}

.mainbox {
  /* Firefox */
}
.mainbox .title {
  text-align: center;
  margin: 0 0 15px;
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 15px;
  color: hsl(199.38, 71.03%, 56.51%);
}
.mainbox input::-webkit-outer-spin-button,
.mainbox input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mainbox input[type=number] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}

.mainbox {
  margin-top: 20px;
  margin-bottom: 30px;
}

.css-13cymwt-control {
  background-color: transparent !important;
  color: white !important;
}

.css-1nmdiq5-menu {
  background-color: black !important;
  color: white !important;
}

.coin-dropdown {
  background-color: transparent !important;
  color: white !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.css-1dimb5e-singleValue {
  color: white !important;
}

.css-13cymwt-control {
  background: transparent !important;
}

.css-t3ipsp-control {
  background: transparent !important;
  color: white;
}

.css-1nmdiq5-menu:hover {
  color: black !important;
}

.a {
  color: #999;
  font: normal 1.1em/3.11em Electrolize, "Cultive Mono", Tahoma, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  height: 60px;
  transition: all 330ms linear;
}
.a span[class^=cta] {
  width: 100%;
  display: block;
  padding: 0 29px;
  text-shadow: 0 0 30px #999;
}
.a span[class^=cta]:before {
  content: attr(data-content);
}
.a .skew {
  transform: skew(-45deg);
  border: 1px solid transparent;
  border-right-width: 2px;
  padding: 24px;
  display: block;
  transition: all 330ms linear;
}

.a.holo-btn-fed {
  color: #00f7ff;
}
.a.holo-btn-fed:hover {
  color: #e5feff;
}
.a.holo-btn-fed .skew {
  -o-border-image: linear-gradient(top left, #99fcff 0%, #00f7ff 100%) 1;
     border-image: linear-gradient(top left, #99fcff 0%, #00f7ff 100%) 1;
}

.swap-icon {
  background: rgba(81, 152, 240, 0.075);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.068);
}

.swap-icon:hover {
  box-shadow: 0 8px 32px 0 rgba(51, 85, 177, 0.63);
  background: rgba(81, 152, 240, 0.322);
}

.analytics-boxmain {
  position: relative;
  text-align: center;
  background: linear-gradient(89.96deg, hsla(0, 0%, 100%, 0.027) 0.03%, hsla(0, 0%, 100%, 0.008) 49.67%, hsla(0, 0%, 100%, 0.014) 99.96%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border: 1px solid hsla(222, 100%, 58%, 0.13);
}

@keyframes swapBorder {
  0%, 100% {
    top: 2%;
  }
  50% {
    top: 80%;
  }
}
@keyframes cardBorder {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
.analytics-boxmain::before {
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  background: linear-gradient(45deg, rgba(24, 24, 27, 0.01) 0%, #fafe14, rgba(24, 24, 27, 0.01) 100%);
  position: absolute;
  top: -1px;
  left: 0%;
  z-index: 99;
  animation: cardBorder 5s infinite;
}

.boxmain {
  position: relative;
  text-align: center;
  background: linear-gradient(89.96deg, hsla(0, 0%, 100%, 0.027) 0.03%, hsla(0, 0%, 100%, 0.008) 49.67%, hsla(0, 0%, 100%, 0.014) 99.96%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border: 1px solid hsla(222, 100%, 58%, 0.13);
}
.boxmain .swapbtn {
  margin: 4px auto;
  text-align: center;
  position: relative;
  z-index: 2;
  background-color: #ff84d9;
  width: 12px;
  height: 12px;
  margin-left: auto;
  margin-right: auto;
  transform: scale(4);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.boxmain .swapbtn img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 6px;
  filter: invert(1);
}

@keyframes swapBorder {
  0%, 100% {
    top: 2%;
  }
  50% {
    top: 80%;
  }
}
.boxmain::before {
  content: "";
  display: block;
  width: 2px;
  height: 20%;
  background: linear-gradient(45deg, rgba(24, 24, 27, 0.01) 0%, rgba(254, 211, 20, 0.9058823529), rgba(24, 24, 27, 0.01) 100%);
  position: absolute;
  top: 0%;
  left: -1px;
  z-index: 99;
  animation: swapBorder 4s infinite;
}

.boxmain-popup {
  position: relative;
  text-align: center;
  background: linear-gradient(89.96deg, hsla(0, 0%, 100%, 0.027) 0.03%, hsla(0, 0%, 100%, 0.008) 49.67%, hsla(0, 0%, 100%, 0.014) 99.96%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border: 1px solid hsla(222, 100%, 58%, 0.212);
}

.boxmain-popup::before {
  content: "";
  display: block;
  width: 2px;
  height: 20%;
  background: linear-gradient(45deg, rgba(24, 24, 27, 0.01) 0%, rgba(254, 211, 20, 0.9058823529), rgba(24, 24, 27, 0.01) 100%);
  position: absolute;
  top: 0%;
  left: -1px;
  z-index: 99;
  animation: swapBorder 4s infinite;
}

.boxinner {
  padding: 40px 30px 40px 30px;
  position: relative;
  text-align: left;
}
.boxinner p {
  margin: 0px;
  text-transform: capitalize;
  color: #a5a5a5;
  font-weight: 400;
  font-size: 16px;
}
.boxinner strong {
  margin: 0px;
  text-transform: capitalize;
  color: #a5a5a5;
  font-weight: 400;
  font-size: 16px;
}
.boxinner strong span {
  color: #ffffff;
}
.boxinner .boxpart {
  margin-top: 20px;
}
.boxinner .leftbx {
  flex-wrap: wrap;
  background: rgba(81, 152, 240, 0.075);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.068);
}
.boxinner .leftbx img {
  display: block;
  width: 40px;
  background: transparent;
  border-radius: 50px;
  padding: 3px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.boxinner .leftbx strong {
  font-weight: 500;
  color: #ffffff;
}
.boxinner .leftbx .btnaddtkn {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 7px;
  color: #ffe6bf;
  font-size: 14px;
  text-decoration: none;
}
.boxinner .leftbx .btnaddtkn:hover {
  text-decoration: underline;
}
.boxinner .leftbx p {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 12px;
  font-size: 14px;
}
.boxinner .leftbx p span {
  color: #ffffff;
}
.boxinner input {
  flex: 1;
  padding: 10px 12px;
  border-radius: 5px;
  background-color: #172538;
  outline: none;
  box-shadow: none;
  color: #ffffff;
  text-align: right;
  width: 100%;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

overlaypopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.83);
  z-index: 3;
}

.ospopup {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(245, 246, 252);
  box-shadow: rgba(51, 53, 72, 0.04) 8px 12px 20px, rgba(51, 53, 72, 0.02) 4px 6px 12px, rgba(51, 53, 72, 0.04) 4px 4px 8px;
  padding: 0px;
  width: 90%;
  overflow: hidden auto;
  max-width: 420px;
  max-height: 90vh;
  border-radius: 20px;
  color: #222222;
  transform: translate(-50%, -50%);
  z-index: 4;
}
.ospopup .popuptitle {
  padding: 15px 15px 20px;
}
.ospopup .popuptitle h3 {
  margin: 0px;
  font-size: 16px;
}
.ospopup .popuptitle button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  position: relative;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.ospopup .popuptitle button svg {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
}
.ospopup .popuptitle button::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
}

.popupbtngrp {
  padding: 0px 7px 15px;
}
.popupbtngrp button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #e8ecfb;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  color: #0d111c;
  font-size: 16px;
  font-weight: 600;
  flex: 0 0 calc(50% - 14px);
  max-width: calc(50% - 14px);
  margin-left: 7px;
  margin-right: 7px;
  font-family: "Montserrat", sans-serif;
}
.popupbtngrp button img {
  height: 28px;
  width: 28px;
  margin-right: 12px;
}

.boxinner.bxinner.space-y-4 {
  margin-top: 20px;
}

button.approvebtn.bxinner.flex.justify-between.items-center.mt-6.bg-blue-500.text-white.p-2.rounded {
  margin-top: 20px;
}

.confirmingtransaction {
  text-align: center;
}
.confirmingtransaction .loaderimg {
  width: 100%;
  max-width: 60px;
}
.confirmingtransaction h2 {
  margin: 20px 0 0;
  font-size: 26px;
  font-weight: 500;
}
.confirmingtransaction .approvebtn {
  max-width: calc(100% - 40px);
  margin: 30px auto 20px;
  border: none;
}

.boxsmain {
  margin-top: 50px;
}
.boxsmain .os_container {
  max-width: 800px;
}
.boxsmain .os_row {
  justify-content: center;
}
.boxsmain .col4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 15px;
}
.boxsmain .cardbox {
  background: linear-gradient(180deg, hsla(209, 85%, 48%, 0.05), hsla(208.21, 95.52%, 50.62%, 0) 73.86%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  border-radius: 15px;
  padding: 20px 15px;
  border-radius: 7px;
  position: relative;
  text-align: center;
  height: 100%;
}
.boxsmain .cardbox h2 {
  margin: 0 0 8px;
  font-weight: 500;
}
.boxsmain .cardbox p {
  margin: 0px;
  font-size: 16px;
}
.boxsmain .cardbox::before {
  content: "";
  display: block;
  width: 20%;
  height: 2px;
  background: linear-gradient(45deg, rgba(24, 24, 27, 0.01) 0%, #fafe14, rgba(24, 24, 27, 0.01) 100%);
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  animation: cardBorder 3s infinite;
}
.boxsmain .titleh2 {
  text-align: center;
}
.boxsmain .titleh2 h2 {
  text-align: center;
  margin: 0 0 15px;
  font-size: 42px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 15px;
}

.content--canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: hue-rotate(1deg);
  opacity: 0.2;
}

.wrapper {
  min-height: 100vh;
}

.adminReportTable .os_container {
  max-width: 800px;
}
.adminReportTable .react-tabs .react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.adminReportTable .react-tabs .react-tabs__tab-list .react-tabs__tab {
  width: 100%;
  padding: 15px;
  text-align: center;
}
.adminReportTable .table-responsive {
  overflow: auto;
}
.adminReportTable .table-responsive .text-right {
  text-align: right;
}
.adminReportTable .table-responsive .thead .tr {
  background-color: #680d48;
}
.adminReportTable .table-responsive .thead .tr .th {
  padding: 15px;
}
.adminReportTable .table-responsive .tbody .tr .td {
  border-bottom: 1px solid #323232;
  padding: 15px 0px;
  margin: 0;
  width: 100%;
}
.adminReportTable .table-responsive .tr .td {
  padding: 10px;
}
.adminReportTable .table-responsive .table {
  width: 100%;
}

/* Customize react-select styles if needed */
.select-dropdown {
  background-color: transparent;
}

.coin-dropdown {
  border-radius: 4px;
  color: black;
}
.coin-dropdown option {
  color: black;
}

.coin-option {
  padding-left: 30px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 5px center;
  min-height: 60px;
}

.token-update__title {
  font-size: 22px;
  font-weight: 600 !important;
  color: white;
  margin-bottom: 45px;
}

.token-update__data li {
  display: flex;
  align-items: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(65, 85, 114, 0.5);
  background-image: linear-gradient(180deg, rgba(65, 85, 114, 0.15) 0%, rgba(27, 27, 30, 0.1) 100%);
  min-height: 60px;
  color: #fff;
  font-size: 18px;
}

.token-update__data li:not(:last-child) {
  margin-bottom: 15px;
}

.token-update__data li span {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  min-height: 60px;
  background-position: right;
  padding-left: 10px;
  background-image: url(../images/token-table-bg.png);
  background-repeat: no-repeat;
  font-size: 16px;
  font-weight: 400;
  color: #b3b3c0;
  filter: drop-shadow(2px 4px 6px rgba(72, 141, 219, 0.637));
}

.token-update__data li strong {
  font-weight: 500;
  margin: 0 auto;
}

.token-table {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(65, 85, 114, 0.5);
  background-image: linear-gradient(180deg, rgba(65, 85, 114, 0.15) 0%, rgba(27, 27, 30, 0.1) 100%);
}

.cardbox2 {
  padding: 10px 20px 20px 20px;
  background: linear-gradient(180deg, hsla(209, 85%, 48%, 0.05), hsla(208.21, 95.52%, 50.62%, 0) 73.86%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border: 1px solid hsla(0, 0%, 100%, 0.068);
  border-radius: 10px;
  position: relative;
  text-align: center;
  height: 100%;
  overflow: hidden;
}

.cardbox2 h2 {
  font-size: 22px;
  font-weight: 600;
}

.cardbox2 p {
  font-size: 16px;
}

.os_row2 {
  display: flex;
  flex-wrap: wrap;
}

.os_row3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.boxsmain2 {
  margin-top: 50px;
}
.boxsmain2 .os_container {
  max-width: 1320px;
}
.boxsmain2 .os_row {
  justify-content: center;
}
.boxsmain2 .col4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 20px;
}

.elementor-background-overlay {
  background-color: #141414;
  background-image: url("https://toka.peerduck.com/wp-content/uploads/2022/04/kgvnrgk.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 40% auto;
  opacity: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.cardbox2 {
  /* background: transparent; */
}
.cardbox2 .vtn-card-img {
  opacity: 0.5;
  filter: hue-rotate(298deg);
}
.cardbox2 .iconbox {
  max-width: 90px;
  margin: 10px 10px;
  position: relative;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
}
.cardbox2 .iconbox img {
  width: 100%;
  height: auto;
}
.cardbox2 p {
  font-size: 16px;
}

@keyframes move {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.table-responsive {
  border: 1px solid hsl(199.38, 71.03%, 56.51%) !important;
}
.table-responsive .text-right {
  text-align: right;
}
.table-responsive thead {
  background-color: transparent;
  background: linear-gradient(rgb(13, 50, 82) 0%, rgb(59, 143, 212) 70.45%);
  --cui-table-color-state: hsl(199.38deg 71.03% 56.51%);
  --cui-border-width: 0px;
}
.table-responsive thead tr {
  background-color: transparent;
  background: linear-gradient(rgb(4, 25, 43) 0%, rgb(4, 7, 19) 70.45%);
}
.table-responsive thead th {
  padding: 15px;
}

tbody tr td {
  border-bottom: 1px solid #323232;
  padding: 15px 0px;
  margin: 0;
}

tr td {
  padding: 10px;
  text-align: center;
}

table {
  width: 100%;
}

.header-cell {
  border-right: 1px solid #fff;
}

.adminReportTable {
  margin-top: 40px;
  margin-bottom: 60px;
  overflow: auto;
}

.adminReportTable2 {
  margin-bottom: 40px;
  overflow: hidden;
}

.boxsmaint {
  margin-top: 50px;
}
.boxsmaint .os_container {
  max-width: 1320px;
}

.css-vubbuv {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.dashboardTable th,
.dashboardTable td {
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
  padding: 8px;
  text-align: left;
}

.historysec {
  padding-block: 50px;
}
.historysec nav {
  justify-content: space-around;
  align-items: center;
  padding-block: 15px;
}
.historysec table thead tr {
  background-color: transparent;
  background-image: linear-gradient(rgb(4, 25, 43) 0%, rgb(4, 7, 19) 70.45%);
  border: 1px solid #fff;
  padding: 8px;
  text-align: left;
  justify-content: space-between;
}
.historysec table {
  position: relative;
  border: 1px solid #791685;
  table-layout: fixed;
}
.historysec table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.historysec .page-link {
  background-color: transparent;
  color: #fff;
}

.titlebox {
  margin-top: 10px !important;
  text-align: center;
  max-width: 500px;
  margin: 0px auto 60px;
  position: relative;
}

.carousel .control-dots {
  position: absolute;
  top: 30px;
  right: 50%;
  transform: translateX(50%);
  bottom: auto;
  flex-direction: row;
  justify-content: center;
}

/* Base Styles */
.carousel-indicator-button {
  width: 100px;
  height: 40px;
  margin-right: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  outline: none;
  cursor: pointer;
}

/* Hover State */
/* Focus State */
.carousel-indicator-button:focus {
  box-shadow: 0 0 0 4px #3f26d9;
}

/* Active State */
.carousel-indicator-active {
  background-color: transparent;
  background-image: linear-gradient(rgb(4, 25, 43) 0%, rgb(4, 7, 19) 70.45%);
  /* bg-blue-500 */
  color: #f9fafb;
  /* text-white */
}

/* Inactive State */
.carousel-indicator-inactive {
  background-color: transparent;
  /* bg-gray-200 */
  color: #fff;
}

/*DATE PICKER */
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiStack-root.MuiMultiInputDateRangeField-root.css-hw8s2-MuiStack-root-MuiMultiInputDateRangeField-root {
  width: 500px !important;
}

body .MuiFormControl-root-MuiTextField-root {
  border: 1px solid !important;
}

body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  width: 500px !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  width: var(--loader-size);
  height: var(--loader-size);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
}
.loader img {
  max-width: 200px;
}

@media only screen and (max-width: 768px) {
  .boxsmain .col4 {
    padding: 8px;
  }
  .boxsmain .cardbox {
    padding: 15px 8px;
  }
  .boxsmain .cardbox p {
    font-size: 16px;
    min-height: 35px;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 768px) {
  .boxsmain2 .col4 {
    padding: 8px;
  }
  .boxsmain2 .cardbox2 {
    padding: 0px 1px;
  }
  .boxsmain2 .cardbox2 p {
    font-size: 16px;
    min-height: 35px;
    line-height: 1.1;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}
@media only screen and (max-width: 575px) {
  .os_container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .boxsmain2 .col4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }
  .analytics-boxmain::before {
    content: "";
    width: 20%;
  }
  .boxinner {
    padding: 25px 10px 25px 10px;
  }
  .popupbtngrp {
    padding: 0px 15px;
  }
  .popupbtngrp button {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .boxsmain .col4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }
  .adminform .boxinner .inputbox button {
    margin-left: 15px;
    min-width: 85px;
    font-size: 14px;
    padding: 10px;
    text-transform: capitalize;
  }
  .adminform .boxinner input {
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }
}
@media only screen and (max-width: 400px) {
  .token-update__data li span {
    gap: 2px;
    min-height: 60px;
    width: 70%;
    padding-left: 5px;
  }
  .token-table {
    padding-left: 10px;
    padding-right: 10px;
  }
  .boxmain {
    padding: 8px;
  }
}/*# sourceMappingURL=style.css.map */