*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.os_container_admin {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.os_row_ad {
  display: flex;
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}



header {

  .os_col {
    flex: 1;
    justify-content: space-between;
  }

 

  .titles {
    font-size: 30px;
    text-align: center;
  }

  .wallet {
    text-align: right;
    display: flex;
    // flex-wrap: wrap;
    button{
      margin-left: 20px;
      min-width: 100px;
      font-size: 16px;
      padding: 10px;
      border-radius: 4px;
      font-weight: 400;
      color: #ffffff;
      text-transform: uppercase;
      background-color: rgba(255, 255, 255, 0.3411764706);
      cursor: pointer;
      border: 1px solid #0c041b;
      transition: all 0.2s ease-in-out;
      position: relative;
      
    }

    img {
      width: 40px;
      height: auto;
      filter: invert(1);
      cursor: pointer;
    }
  }
}

.mainbox_ad {
  .title {
    text-align: center;
    margin: 0 0 15px;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 0;
    margin-bottom: 15px;
    color: #ffa902;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    appearance: textfield;
  }
}

#root {
  min-height: 100vh;
}

.mainbox_ad {
  margin-top: 20px;
  margin-bottom: 30px;
}

.boxmain_ad {
  position: relative;
  text-align: center;
  top: 50px;
 
 
  .swapbtn {
    margin: 4px auto;
    text-align: center;
    position: relative;
    z-index: 2;
    background-color: rgb(236 72 153 );
    width: 12px;
    height: 12px;
    margin-left: auto;
    margin-right: auto;
    transform: scale(4);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 6px;
      filter: invert(1);
    }
  }
}


.approvebtn {
  padding: 0;
  width: 50%;
  height: 60px;
  font-family:"Exbit-regular";
  border-radius: 5px;
  border: 1px solid hsl(199.38deg 71.03% 56.51%);
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  margin: 0 auto;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  margin-top: 25px;
  color: #ffffff;
  font-size: 22px;

  &.mt-25 {
    margin-top: 25px;
  }

  img {
    height: 14px;
    width: auto;
    position: relative;
    left: 8px;
    filter: invert(1);
  }

  span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 15px 0px;

    &::before,
    &::after {
      position: absolute;
      content: "";
      right: 0;
      bottom: 0;
      background: hsl(199.38, 71.03%, 56.51%);
      transition: all 0.3s ease;
    }

    &::before {
      width: 2px;
      height: 0%;
    }

    &::after {
      width: 0%;
      height: 2px;
    }

    &:hover {
      &::before {
        height: 100%;
      }

      &::after {
        width: 100%;
      }
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: hsl(199.38, 71.03%, 56.51%);
    transition: all 0.3s ease;
  }

  &::before {
    height: 0%;
    width: 2px;
  }

  &::after {
    width: 0%;
    height: 2px;
  }

  &:hover {
    background: transparent;
    color: hsl(199.38, 71.03%, 56.51%);

    &::before {
      height: 100%;
    }

    &::after {
      width: 100%;
    }
  }
}

.boxinner_ad {
 
  background: linear-gradient(180deg, hsla(209, 85%, 48%, 0.05), hsla(208.21, 95.52%, 50.62%, 0) 73.86%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border: 1px solid hsla(0, 0%, 100%, 0.1);

  padding: 10px 20px 30px;
  border-radius: 7px;
  position: relative;
  text-align: left;
 




  p {
    margin: 0px;
    text-transform: capitalize;
    color: #a5a5a5;
    font-weight: 400;
    font-size: 16px;
  }

  strong {
    margin: 0px;
    text-transform: capitalize;
    color: #a5a5a5;
    font-weight: 400;
    font-size: 16px;

    span {
      color: #ffffff;
    }
  }

  .boxpart_ad {
    margin-top: 20px;
  }

  .leftbx_ad {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    img {
      display: block;
      width: 30px;
      margin-right: 10px;
      max-width: 30px;
      background-color: #ffffff;
      border-radius: 50px;
      padding: 3px;
      height: 30px;
      object-fit: contain;
    }

    strong {
      font-weight: 500;
      color: #ffffff;
    }

    .btnaddtkn {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 7px;
      color: #ffe6bf;
      font-size: 14px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 12px;
      font-size: 14px;

      span {
        color: #ffffff;
      }
    }
  }

  input {
    flex: 1;
    margin-left: 0px;
    background-color: #172538;
    padding: 10px 12px;
    border-radius: 5px;
   
    outline: none;
    box-shadow: none;
    color: #ffffff;
    text-align: left;
    width: 100%;
    font-size: 16px;
  }
}
@keyframes cardBorder {
  0%, 100% {
    left: 10%;
  }
  50% {
    left: 80%;
  }
}

.boxinner_ad::before {
  content: "";
  display: block;
  width: 20%;
  height: 2px;
  background: linear-gradient(45deg, rgba(24, 24, 27, 0.01) 0%, #fafe14, rgba(24, 24, 27, 0.01) 100%);
  position: absolute;
  top: -1px;
  left: 50%; 
  transform: translateX(-50%); 
  z-index: 99;
  animation: cardBorder 3s infinite;
}

.overlaypopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.83);
  z-index: 3;
}

.ospopup {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(245, 246, 252);
  box-shadow: rgba(51, 53, 72, 0.04) 8px 12px 20px, rgba(51, 53, 72, 0.02) 4px 6px 12px, rgba(51, 53, 72, 0.04) 4px 4px 8px;
  padding: 0px;
  width: 90%;
  overflow: hidden auto;
  max-width: 420px;
  max-height: 90vh;
  border-radius: 20px;
  color: #222222;
  transform: translate(-50%, -50%);
  z-index: 4;

  .popuptitle {
    padding: 15px 15px 20px;

    h3 {
      margin: 0px;
      font-size: 16px;
    }

    button {
      background-color: transparent;
      padding: 0;
      margin: 0;
      width: 25px;
      height: 25px;
      border-radius: 50px;
      position: relative;
      border: none;
      cursor: pointer;

      svg {
        position: absolute;
        top: 45%;
        left: 45%;
        transform: translate(-50%, -50%);
      }

      &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
      }
    }
  }
}

.popupbtngrp {
  padding: 0px 7px 15px;

  button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    padding: 15px;
    background-color: #e8ecfb;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    color: #0d111c;
    font-size: 16px;
    font-weight: 600;
    flex: 0 0 calc(50% - 14px);
    max-width: calc(50% - 14px);
    margin-left: 7px;
    margin-right: 7px;

    img {
      height: 28px;
      width: 28px;
      margin-right: 12px;
    }
  }
}

.osprivacy {
  padding: 10px 15px 15px;
  margin-bottom: 10px;
  color: #7780a0;

  a {
    text-decoration: none;
    cursor: pointer;
    color: #fb118e;
    font-weight: 500;
  }
}

.confirmingtransaction {
  text-align: center;

  .loaderimg {
    width: 100%;
    max-width: 60px;
  }

  h2 {
    margin: 20px 0 0;
    font-size: 26px;
    font-weight: 500;
  }

  .approvebtn {
    max-width: calc(100% - 40px);
    margin: 30px auto 20px;
    border: none;
  }
}

.boxsmain_ad {
  margin-top: 50px;

  .os_container {
    max-width: 1320px;
  }

  .os_row {
    // justify-content: space-between;
    justify-content: center;
  }

  .col4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 15px;
    ;
  }

  .cardbox {
    padding: 20px 15px;
    border-radius: 7px;
    position: relative;
    text-align: center;
    height: 100%;
    backdrop-filter: blur(3px);
    /* background: transparent; */
    .iconbox {
      max-width: 100px;
      margin: 0 auto;
      position: relative;
      border-radius: 50%;
      padding: 0;
      margin-bottom: 15px;
      img{
        width: 100%;
        height: auto;
        animation: move 2s linear infinite;
      }
    }

    h2 {
      margin: 0 0 8px;
      font-size: 20px;
      font-weight: 600;
    }

    p {
      margin: 0px;
      font-size: 16px;
    }

  
  }
  @keyframes move {
   
    0% {transform: translateY(0px);}
    50% {transform: translateY(-10px);}
    100% {transform: translateY(0px);}
  }

  .titleh2 {
    text-align: center;

    h2 {
      font-family: "Poppins";
      letter-spacing: 0.01em;
      text-align: center;
      margin: 0 0 10px;
      font-size: 40px;
      font-weight: 700;
      text-transform: capitalize;
      padding: 15px;
      // color: #ffa902;
    }
  }
}
.inputbox_ad {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0;
  margin: 0;

  button {
    margin-left: 20px;
    min-width: 100px;
    font-size: 16px;
    padding: 10px;
    border-radius: 4px;
    font-weight: 400;
    color: #ffffff;
    text-transform: uppercase;
    background-color: hsla(207, 71%, 56%, 0.705);;
    cursor: pointer;
    border: 1px solid #426a9b;
    transition: all 0.2s ease-in-out;
    position: relative;



    &:hover {
      border: 1px solid #c9c9c9;

      &::before,
      &::after {
        border-color: #c9c9c9;
      }
    }
  }
}
.leftbx_ad {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 5px;
}
form {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
}
input {
  text-align: left;
  // flex: 0 0 calc(100% - 120px);
  // max-width: calc(100% - 120px);
  margin-left: 0px;
  width: 100%;
}
.Emergencydrop {
  border: 1px solid #426a9b;
  width: 100%;
  background: #0a354ef7;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
}


.content--canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: hue-rotate(-252deg);
  opacity: 0.5;
}

.wrapper {
  min-height: 100vh;
}



.adminReportTable {
  .os_container_admin {
    max-width: 1320px;
  }

  .react-tabs {
    .react-tabs__tab-list {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .react-tabs__tab {
        width: 100%;
        padding: 15px;
        text-align: center;
        background: rgb(246 178 51);
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .table-responsive {
    .text-right {
      text-align: right;
    }

    thead {
      tr {
        background-color: #23163a;

        th {
          padding: 15px;
          text-align: center;
      font-size: 15px;
      border-bottom: 1px solid hsl(199.38deg 71.03% 56.51%);

        }
      }
    }

    tbody {
      tr {
        td {
          border-bottom: 1px solid #323232;
          padding: 10px;
          margin: 0;
          text-align: center;
          background: black;
          color: #ffffffeb;
        }
      }
    }

    tr {
      td {
        padding: 10px;
      }
    }

    table {
      width: 100%;
    }
  }
}
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {

  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

//-------adminreporttable--------------//
.adminReportTable {
  .os_container {
    max-width: 1320px;
  }

  .react-tabs {
    .react-tabs__tab-list {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .react-tabs__tab {
        width: 100%;
        padding: 15px;
        text-align: center;
        background: linear-gradient(rgb(0 61 114) 0%, rgb(59, 143, 212) 70.45%);
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .table-responsive {
    .text-right {
      text-align: right;
    }

    thead {
      tr {
        background-color: #23163a;

        th {
          padding: 15px;
          text-align: center;
      font-size: 15px;
      // border-bottom: 1px solid #fdc06c;

        }
      }
    }

    tbody {
      tr {
        td {
          border-bottom: 1px solid #323232;
          padding: 10px;
          margin: 0;
          text-align: center;
          background: black;
          color: #ffffffeb;
        }
      }
    }

    tr {
      td {
        padding: 10px;
      }
    }

    table {
      width: 100%;
    }
  }
}
.justify-content-between {
  justify-content: space-between!important;
}
.form {
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: var(--width-of-input);
  position: relative;
  isolation: isolate;
  margin: 0 auto;
}
.form {
  --input-text-color: #fff;
  --input-bg-color: #283542;
  --focus-input-bg-color: transparent;
  --text-color: #949faa;
  --active-color: #fdc06c;
  --width-of-input: 200px;
  --inline-padding-of-input: 1.2em;
  --gap: 0.9rem;
}
/* label styling */
label {
  width: 100%;
  padding: 0.8em;
  height: 40px;
  padding-inline: var(--inline-padding-of-input);
  display: flex;
  align-items: center;
  .input {
    color: var(--input-text-color);
    width: 100%;
    margin-inline: min(2em, var(--inline-padding-of-input) + var(--gap));
    background: none;
    border: none;
}
}

.search,
.close-btn {
  position: absolute;
}
body .btnexcel{
  margin-top: 15px !important ;
  // margin: 0 auto;
  // margin-left: auto !important;
  display: block !important;
  // background-color: hsl(199.38, 71.03%, 56.51%);
}
body .btnexcel2{
  margin-top: 15px !important ;
  // margin: 0 auto;
  margin-left: auto !important;
  display: block !important;
  // background-color: hsl(199.38, 71.03%, 56.51%);
} 

/*DATE PICKER */
.abcde {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  .MuiSvgIcon-fontSizeMedium {
    position: absolute;
    top: 40%;
    right: 10px;
    transform: translateY(-50%);
  }
}
body .MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
body .MuiStack-root.MuiMultiInputDateRangeField-root {
  width: 500px !important;
  margin: 0 auto;
}
body .MuiFormControl-root-MuiTextField-root {
  border: 1px solid !important;
}
body
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth {
  max-width: 500px !important;
}
body
  .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
  border: 1px solid #426A9B !important;
}
body
  .MuiFormControl-root.MuiTextField-root {
  margin: 0 auto;
  margin-bottom: 20px;
}
body .MuiInputBase-root.MuiOutlinedInput-root
   {
  border: 1px solid hsl(199.38, 71.03%, 56.51%) !important;
}
body
  .MuiPickersLayout-contentWrapper
  .MuiDateRangeCalendar-root
  > div:nth-child(1) {
  opacity: 0;
  visibility: hidden;
}
body .MuiOutlinedInput-input {
  text-align: center !important;
  color: #fff !important;
}
/* styling search-icon */
.search {
  fill: var(--text-color);
  left: var(--inline-padding-of-input);
}
svg {
  width: 17px;
  display: block;
}
.fancy-bg {
  position: absolute;
  width: 100%;
  inset: 0;
  background: var(--input-bg-color);
  border-radius: 30px;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.input:valid ~ .close-btn {
  opacity: 1;
  visibility: visible;
}
.close-btn {
  border: none;
  right: var(--inline-padding-of-input);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: hsl(199.38, 71.03%, 56.51%);
  opacity: 0;
  visibility: hidden;
}
body .MuiBox-root {
  background-color: transparent;
}
.css-1azz2d9 {
  width: 100%;
  background-color: #000;
  margin-bottom: 50px;
  margin-top: 30px;
}
.css-tkn350-MuiTabs-root {
  overflow: hidden;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.css-69z67c-MuiTabs-scroller {
  position: relative;
  display: inline-block;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  white-space: nowrap;
  scrollbar-width: none;
  overflow-x: auto;
  overflow-y: hidden;
}
.hello .MuiTabs-flexContainer {
  justify-content: center;
}
.css-heg063-MuiTabs-flexContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.MuiTabs-flexContainer {
  display: flex;
}
.MuiTabs-flexContainer {
  justify-content: space-between;
}
.MuiTabs-scroller .MuiTabs-flexContainer button.Mui-selected {
  background: transparent;
  font-weight: 600;
  background-size: 200%;
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
}
.css-tkn350-MuiTabs-root {
  overflow: hidden;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.MuiTabs-scroller .MuiTabs-flexContainer button {
  color: #fff;
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
  margin: 0 10px;
  border-radius: 6px;
  font-size: 16px;
  min-width: 90px;
  background-color: transparent;
}
.css-8je8zh-MuiTouchRipple-root {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.Mui-selected {
  background: linear-gradient(rgb(0 61 114) 0%, rgb(59, 143, 212) 70.45%) !important;
  color: #000 !important;
}
.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
  opacity: 0;
}
.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
  pointer-events: none;
  cursor: default;
}
.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  width: 40px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  opacity: 0.8;
}
.css-ptiqhd-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}
.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  width: 40px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  opacity: 0.8;
}
.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  width: 40px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  opacity: 0.8;
}

.prevbtn {
  display: flex;
  /* display: block; */
  /* justify-content: center; */
  /* text-align: center; */
  justify-content: space-between;
  margin-top: 10px;
  column-gap: 10px;
  button{
    padding: 10px;
    background: transparent;
    color: #fff;
    border: 1px solid hsl(199.38, 71.03%, 56.51%);
  }
}



 .inputswapbtn{
    word-wrap: normal;
    position: absolute;
    left: 265px;
    margin-top: 12px;
    border-radius: 10%;
    border: 1px solid;
    background-color: #0a354ef7;
    color: #fff;
    
    
 }
 @media only screen and (max-width: 768px) {
  .boxsmain .col4 {
    padding: 8px;
  }

  .boxsmain .cardbox {
    padding: 15px 8px;
  }

  .boxsmain .cardbox p {
    font-size: 16px;
    min-height: 35px;
    line-height: 1.1;
  }
}

@media only screen and (max-width: 575px) {
  .os_container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .approvebtn {
    font-size: 15px;
  }

  .boxinner_ad {
    padding: 25px 10px 60px;
  }

  .popupbtngrp {
    padding: 0px 15px;

    button {
      flex-basis: 100%;
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }



  .boxsmain .col4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }

  .adminform_ad .boxinner_ad .inputbox_ad button {
    margin-left: 15px;
    min-width: 73px;
    font-size: 14px;
    padding: 10px;
    text-transform: capitalize;
  }
 
  .adminform_ad .boxinner_ad input {
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }
  .inputbox_ad button {
    margin-left: 15px;
    min-width: 73px;
    font-size: 14px;
    padding: 10px;
    text-transform: capitalize;
  }
  input {
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }
  .inputswapbtn {
    word-wrap: normal;
    margin-top: 0px;
    border-radius: 10%;
    background-color: #2b2b2d;
    color: #fff;
    font-size: 15px;
   
    margin-top: 6px;
    margin-bottom: 6px;
    position: static;
}

}
