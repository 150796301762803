.footer {
    border: 1px solid transparent !important;
    background-color: transparent;
    align-items: center;
    display: flex;
    min-height: 48px;
    flex-wrap: wrap;
    padding: 8px;
    position: sticky;
    width: 100%;
    top: 100%;
}
#root>[data-rk]{
    min-height: 100vh;
}
.footer_inner_row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.footer_copyright_wrapper {
    font-family: "techmont-bold", sans-serif;
}

.footer-seperator-line {
    background-image: linear-gradient(30deg, transparent, #5c9af6 50%, transparent);
    height: 1.5px;
    margin-bottom: 8px;
    margin-top: 8px;
    padding-top: 0;
    width: 100%;
}

// .footer_copyright_text {
//     color: #8b5cf6;
// }
.community_icon_wrapper {
    grid-column-gap: 20px;
    grid-row-gap: 32px;
    align-items: center;
    display: -ms-grid;
    display: flex;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    svg {
        vertical-align: middle;
        height: 28px;
        width: 28px;
    }
}

// :not(svg) {
//     transform-origin: 0px 0px;
// }
.footer-cta {
    background-image: linear-gradient(to right, rgba(229, 14, 42, 0), (#5ca4f6) 65%);
    color: #fff;
    -webkit-text-stroke-width: 1px;
    width: 100%;
    height: 9.13rem;
    font-family: "techmont-bold", sans-serif;
    font-size: 76px;
    font-weight: 700;
    line-height: 76px;
    text-decoration: none;
    display: block;
    position: relative;
    overflow: hidden;
}

.icon-holder {
    width: 44px;
    height: 44px;
    background-color: rgba(7, 113, 184, 0.2);
    border: 1px solid #5c9af6;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    display: flex;
    transform: rotate(45deg);
}
.icon-holder:hover {
    box-shadow: 0px 6px 30px #0b75fff6;
}
.ftr-icon {
    transform: rotateZ(-45deg);
    transform-origin: center;
}

@media only screen and (max-width: 991px) {
    .footer-cta {
        height: 7rem;
    }
}

@media only screen and (max-width: 554px) {
    .footer_copyright_wrapper {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }

    .footer-cta {
        height: 5.5rem;
    }
}

@media only screen and (max-width: 400px) {
    .footer_copyright_wrapper {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }
}
